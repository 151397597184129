<template>
  <div class="home">
      <div style="margin-bottom: 34px;">
        <img style="width: 100%;height:auto"  :src="'https://szsp-static.zhengtengfilm.com/' + img" alt="">
      </div>
       <p >登录</p>
       <div style="padding: 0 40px;box-sizing: border-box;">
        <div style="display: flex; align-items: center; margin-top: 30px">
        <!-- <i style="font-size: 30px; margin-right: 5px" class="el-icon-unlock"></i> -->
        <el-input v-model="password" placeholder="请输入手机号">
          <i slot="prefix" class="el-input__icon el-icon-mobile-phone
"></i>
        </el-input>
      </div>
      <div style="display: flex; align-items: center; margin-top: 30px">
        <!-- <i style="font-size: 30px; margin-right: 5px" class="el-icon-connection"></i> -->
        <el-input ref="formdescInputs" v-model="code" @keyup.enter.native="submit" placeholder="请输入验证码"></el-input>
        <el-button style="margin-left: 10px;background:#F16A2A;border:none;border-radius: 30px;" :disabled="dis" type="primary" @click="getImg">
          {{ show ? "获取验证码" : count + "s" }}
        </el-button>
      </div>

      <el-button style="width: 200px;margin-top: 20px;background:#F16A2A;border:none;border-radius: 30px;" type="primary" @click="submit">登录</el-button>

       </div>

       <el-dialog width="90%" title="获取验证码" :visible.sync="dialogTableVisible">
        <div style="display: flex; align-items: center; margin-top: 30px">
        <!-- <i style="font-size: 30px; margin-right: 5px" class="el-icon-connection"></i> -->
        <el-input ref="formdescInput" @keyup.enter.native="getCode" v-model="captcha" placeholder="请输入验证码"></el-input>
         <img @click="addKey" v-if="dialogTableVisible" :src="'https://sapi.zhengtengfilm.com/api/captcha?r='+keyNum" alt="">
      </div>
      <div @click="addKey" style="text-align: right;padding-right:20px;color:blue">点击刷新验证码</div>

      <div>
        <el-button style="width: 200px;margin-top: 20px;background:#F16A2A;border:none;border-radius: 30px;" type="primary" @click="getCode">确认</el-button>
      </div>
      </el-dialog>
  
    
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HomeView",
  components: {},
  data () {
    return {
      password: "",
      code: "",
      dis: false,
      captcha:'',
      show: true,
      isGrey: false, //按钮样式
      timer: null, //设置计时器,
      count: "",
      img: "",
      dialogTableVisible:false,
      movie_id:'',
      keyNum:1
    };
  },
  created() {
    let that =this
    let movie_id = this.$route.query.id||''
    if(movie_id){
        localStorage.setItem(
        "movie_id",
         movie_id
       );
    }else{
      movie_id = JSON.parse(localStorage.getItem("movie_id"))
    }
     
    this.movie_id =movie_id
     axios.defaults.withCredentials = true
      axios.defaults.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
          'X-Requested-With':'XMLHttpRequest',
      }
      axios
      .get(`https://sapi.zhengtengfilm.com/api/get-movieinfo?movie_id=${movie_id}`)
      .then(function (res) {
         that.img = res.data.data.movie_info.img;
      })
      .catch(function (error) {
        console.log(error);
      });
      // axios
      //   // .get('https://mapi.zhengtengfilm.com/japi/get-user-info',{ withCredentials: true})
      //   .get('https://sapi.zhengtengfilm.com/api/tlogin', { withCredentials: true })
      //   // .get('https://sapi.zhengtengfilm.com/api/tlogin', { withCredentials: true })
      //   .then(function (res) {
      //      // 没有登录
      //     if(res.data.code==403){
      //       return false
      //     }else{
      //       that.$router.push({
      //         path: "/about"
      //       });
      //     }
           

      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });

  },
  methods: {
    submit () {
      let that = this
      axios.defaults.withCredentials = true
      axios.defaults.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        // 'X-Requested-With':'XMLHttpRequest',
      }
      axios
        .get('https://sapi.zhengtengfilm.com/api/check-sms?phone=' + this.password + '&code=' + this.code,{ withCredentials: true})
        // .get('https://mapi.zhengtengfilm.com/japi/check-sms-test', { withCredentials: true })
        .then(function (res) {
 
          if (res.data.success) {
              that.$router.push({
              path: "/?id="+that.movie_id
            });
          } else {
            that.$message({
              message: res.data.msg,
              type: 'warning'
            });
          }
          that.$refs.formdescInputs.$refs.input.focus();
          that.$nextTick(() => {
            that.$refs.formdescInputs.$refs.input.focus();
          });


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getImg(){
     this.dialogTableVisible=true
    },
    addKey(){
      this.keyNum+=1
    },
    getCode () {
       let that =this
      if (!this.password || this.password.length !== 11) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
        that.$refs.formdescInput.$refs.input.focus();
        that.$nextTick(() => {
          that.$refs.formdescInput.$refs.input.focus();
        });
        return false;
      }
      let TIME_COUNT = 60;
       // 为给定 ID 的 user 创建请求
       axios
          .get("https://sapi.zhengtengfilm.com/api/get-code?phone=" + this.password+'&code='+this.captcha)
          .then(function (response) {
             if(response.data.code!==500){
              that.dialogTableVisible =false
               if (!that.timer) {
                  that.count = TIME_COUNT;
                  that.isGrey = true;
                  that.show = false;
                  that.dis = true;
                  that.timer = setInterval(() => {
                    if (that.count > 0 && that.count <= TIME_COUNT) {
                      that.count--;
                    } else {
                      that.dis = false;
                      that.isGrey = false;
                      that.show = true;
                      clearInterval(that.timer);
                      that.timer = null;
                    }
                  }, 1000);
               }
            }else{
              that.$message({
                message: response.data.msg,
                type: 'warning'
              });
            }
              
            that.$refs.formdescInput.$refs.input.focus();
            that.$nextTick(() => {
              that.$refs.formdescInput.$refs.input.focus();
            });
          })
          .catch(function (error) {
            console.log(error);
          });
  
    },
  },
};
</script>
<style scoped>
.container {
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  font-size: 14px;
}

.main {
  width: 90%;
  height: 250px;
  background: #ffffff;
  border-radius: 8px;
  position: fixed;
  z-index: 2;
  left: 50%;
  margin-left: -45%;
  top: 160px;
  padding: 10px 30px;
  box-sizing: border-box;
}

p {
  font-size: 24px;
  color: #000000;
  font-weight: 500;
  height: 34px;
  line-height: 34px;
  text-align: center;
}
.home{
  padding: 24px;
  box-sizing: border-box;
  height: 100vh;
  background: #F5F6FA;}

  ::v-deep .el-input__inner{
    border-radius: 30px;
  }

</style>
